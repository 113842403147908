import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  DirectionsIcon,
  GearInABoxIcon,
  PieIcon,
  MapPinIcon,
  PathIcon,
  TicksIcon,
  TrucksIcon,
  MesureIcon,
  ReduceIcon,
  CompensateIcon,
} from "../components/svg-assets/Icons";
import WeNowLogo from "../components/svg-assets/WeNowLogo.js";
import FeaturedBusinessCaseBlock from "../components/FeaturedBusinessCaseBlock";

import theFrontOfThreeTrucks from "../images/the-front-of-three-trucks.jpg";
import bigTruckLandscape from "../images/big-trucks-landscape.svg";
import talkingInsideAWarehouse from "../images/talking-inside-a-warehouse.jpg";
import truckLeavingADepot from "../images/truck-leaving-a-depot.jpg";
import roadWithTrees from "../images/road-with-trees.jpg";
import palm from "../images/palm.svg";
import FourTrucks from "../components/svg-assets/FourTrucks";
import TOSWheel from "../components/svg-assets/TOSWheel";
import FretlinkLogo from "../components/svg-assets/FretlinkLogo";
import AnchorLink from "../components/AnchorLink";
import fretlinkDeskFromTop from "../images/fretlink-desk-from-top.jpg";

const ShippersPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <SEO title={t("shippers.html-title")} />
      <div className="fl-bg-bubble" style={{ left: "calc(50% - 552px - 415px)", top: "-134px" }} />
      <div className="fl-grid fl-top-element">
        <div className="fl-first-half fl-green-context">
          <div className="fl-top-block">
            <div className="fl-tag">
              <Trans i18nKey="shippers.headers.tag" />
            </div>
            <h2>
              <Trans i18nKey="shippers.headers.title" />
            </h2>
          </div>
        </div>
        <div className="fl-very-second-half fl-top-block">
          <p className="fl-strong">
            <Trans i18nKey="shippers.headers.p-optimize" />
          </p>
          <Trans parent="p" i18nKey="shippers.headers.p-adopt-a-new-standard" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "108px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={theFrontOfThreeTrucks} alt="" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="shippers.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="shippers.first-block.title" />
            </h2>
            <Trans parent="p" i18nKey="shippers.first-block.p-it-evolves" />
            <Trans parent="p" i18nKey="shippers.first-block.p-at-the-condition" />
            <Trans parent="p" i18nKey="shippers.first-block.p-for-executives" />
          </div>
        </div>

        <div className="fl-second-half fl-svg-width-wrapper">
          <FourTrucks />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "128px" }}>
        <div className="fl-very-first-half">
          <TOSWheel />
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="shippers.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="shippers.second-block.title" />
            </h2>
            <Trans parent="p" i18nKey="shippers.second-block.p-fretlink-is-a-new-generation" />
            <Trans parent="p" i18nKey="shippers.second-block.p-to-build-such-a-standard" />
            <Trans parent="p" i18nKey="shippers.second-block.p-thanks-to-fine-knowledge" />
            <Trans parent="p" i18nKey="shippers.second-block.p-it-gather-all-the-steps" />
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "102px" }}>
        <div className="fl-both-half">
          <h2>
            <Trans i18nKey="shippers.benefits.title" />
          </h2>
        </div>
      </div>

      <div className="fl-grid fl-three-cards" style={{ marginTop: "72px", marginBottom: "104px" }}>
        <section>
          <DirectionsIcon />
          <h3>
            <Trans i18nKey="shippers.benefits.three-cards.first.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.benefits.three-cards.first.content" />
          </p>
        </section>
        <section>
          <GearInABoxIcon />
          <h3>
            <Trans i18nKey="shippers.benefits.three-cards.second.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.benefits.three-cards.second.content" />
          </p>
        </section>
        <section>
          <PieIcon />
          <h3>
            <Trans i18nKey="shippers.benefits.three-cards.third.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.benefits.three-cards.third.content" />
          </p>
        </section>
      </div>

      {i18n.language === "fr" ? <FeaturedBusinessCaseBlock /> : undefined}

      <div
        className="fl-bg-right fl-resp-sm-hide"
        style={{
          marginTop: "75px",
          marginLeft: "60px",
          width: "calc(50% - 60px)",
          height: "1200px",
        }}>
        <img
          src={bigTruckLandscape}
          alt=""
          style={{ height: "1401px", marginTop: "-500px" }}
          loading="lazy"
        />
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-blue-context">
          <div className="fl-medium-block">
            <h2>
              <Trans i18nKey="shippers.how-fretlink.title" />
            </h2>
            <p className="fl-strong">
              <Trans i18nKey="shippers.how-fretlink.p-we-offer-two-programs" />
            </p>
            <Trans parent="p" i18nKey="shippers.how-fretlink.p-whatever-you-choose" />
            <p className="fl-link-next">
              <Link to="/transport-operating-system/">
                <Trans i18nKey="shippers.how-fretlink.learn-more-about-tos" />
              </Link>
            </p>
            <Trans parent="p" i18nKey="shippers.how-fretlink.p-a-team-is-here" />
          </div>
        </div>
        <div className="fl-last-third fl-blue-context fl-sheet">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="shippers.how-fretlink.sheet.tag" />
          </div>
          <ol className="fl-big-white-links fl-blue-numbers">
            <li className="fl-link-next">
              <AnchorLink href="#01-lead-pl">
                <Trans i18nKey="shippers.how-fretlink.sheet.01-lead-pl" />
              </AnchorLink>
            </li>
            <li className="fl-link-next">
              <AnchorLink href="#02-freight-forward">
                <Trans i18nKey="shippers.how-fretlink.sheet.02-freight-forward" />
              </AnchorLink>
            </li>
            <li className="fl-link-next">
              <AnchorLink href="#03-green-roads">
                <Trans i18nKey="shippers.how-fretlink.sheet.03-green-roads" />
              </AnchorLink>
            </li>
          </ol>
        </div>
      </div>

      <div id="01-lead-pl" className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={talkingInsideAWarehouse} loading="lazy" alt="" />
          <div className="fl-first-half fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="shippers.lead-pl.tag" />
            </div>
            <h2>
              <Trans i18nKey="shippers.lead-pl.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid">
        <div className="fl-first-half fl-medium-block" style={{ marginTop: "64px" }}>
          <h2>
            <Trans i18nKey="shippers.lead-pl.centralize-suppliers" />
          </h2>
          <Trans parent="p" i18nKey="shippers.lead-pl.p-lead-pl-explanation" />
          <p className="fl-link-next">
            <Link to="/sign-up/shipper/">
              <Trans i18nKey="shippers.lead-pl.be-contacted" />
            </Link>
          </p>
        </div>
        <div
          className="fl-last-third fl-blue-context fl-sheet fl-over-sheet"
          style={{ marginTop: "-372px" }}>
          <ol>
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.01-you-chose-the-perimeter" />
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.02-fretlink-contact-carriers" />
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.03-defining-together-sla" />
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.04-executing-the-plan" />
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.05-real-time-transport" />
            <Trans parent="li" i18nKey="shippers.lead-pl.sheet.06-performance-reports" />
            <li className="fl-plus">
              <Trans i18nKey="shippers.lead-pl.sheet.plus" />
            </li>
          </ol>
        </div>
      </div>
      <div className="fl-grid fl-three-cards" style={{ marginTop: "56px", marginBottom: "104px" }}>
        <section>
          <MapPinIcon />
          <h3>
            <Trans i18nKey="shippers.lead-pl.three-cards.first.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.lead-pl.three-cards.first.content" />
          </p>
        </section>
        <section>
          <PathIcon />
          <h3>
            <Trans i18nKey="shippers.lead-pl.three-cards.second.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.lead-pl.three-cards.second.content" />
          </p>
        </section>
        <section>
          <PieIcon />
          <h3>
            <Trans i18nKey="shippers.lead-pl.three-cards.third.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.lead-pl.three-cards.third.content" />
          </p>
        </section>
      </div>

      <div id="02-freight-forward" className="fl-grid" style={{ marginTop: "85px" }}>
        <div className="fl-pan fl-pan--left-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={truckLeavingADepot} loading="lazy" alt="" />
          <div className="fl-first-half fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="shippers.freight-forward.tag" />
            </div>
            <h2>
              <Trans i18nKey="shippers.freight-forward.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid">
        <div className="fl-first-half fl-medium-block" style={{ marginTop: "64px" }}>
          <h2>
            <Trans i18nKey="shippers.freight-forward.securize-capacities" />
          </h2>
          <Trans parent="p" i18nKey="shippers.freight-forward.p-freight-forward-explanation" />
          <p className="fl-link-next">
            <Link to="/sign-up/shipper/">
              <Trans i18nKey="shippers.freight-forward.be-contacted" />
            </Link>
          </p>
        </div>
        <div
          className="fl-last-third fl-blue-context fl-sheet fl-over-sheet"
          style={{ marginTop: "-282px" }}>
          <ol>
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.01-study-office" />
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.02-partners-positions" />
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.03-we-pilots" />
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.04-you-visualize" />
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.05-performance-reports" />
            <Trans parent="li" i18nKey="shippers.freight-forward.sheet.06-we-suggest" />
          </ol>
        </div>
      </div>
      <div className="fl-grid fl-three-cards" style={{ marginTop: "56px", marginBottom: "104px" }}>
        <section>
          <TrucksIcon />
          <h3>
            <Trans i18nKey="shippers.freight-forward.three-cards.first.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.freight-forward.three-cards.first.content" />
          </p>
        </section>
        <section>
          <TicksIcon />
          <h3>
            <Trans i18nKey="shippers.freight-forward.three-cards.second.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.freight-forward.three-cards.second.content" />
          </p>
        </section>
        <section>
          <GearInABoxIcon />
          <h3>
            <Trans i18nKey="shippers.freight-forward.three-cards.third.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.freight-forward.three-cards.third.content" />
          </p>
        </section>
      </div>

      {/* Green roads */}
      <div
        className="fl-bg-left"
        style={{ marginTop: "-360px", marginRight: "-120px", width: "calc(50% + 120px)" }}>
        <div className="fl-hider-gradient" />
        <img src={palm} alt="" loading="lazy" />
      </div>
      <div id="03-green-roads" className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={roadWithTrees} loading="lazy" alt="" />
          <div className="fl-first-third fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="shippers.green-roads.tag" />
            </div>
            <h2>
              <Trans i18nKey="shippers.green-roads.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "72px" }}>
        <div className="fl-first-third">
          <h2>
            <Trans i18nKey="shippers.green-roads.reduce-your-footprint" />
          </h2>
          <div className="fl-partnership">
            <div className="fl-partnership-with">
              <Trans i18nKey="shippers.green-roads.in-partnership-with" />
            </div>
            <WeNowLogo />
          </div>
        </div>
        <div className="fl-second-half fl-medium-block fl-green-context">
          <p className="fl-strong">
            <Trans i18nKey="shippers.green-roads.a-specific-approach" />
          </p>
          <Trans parent="p" i18nKey="shippers.green-roads.p-partenered-with-wenow" />
          <p className="fl-link-next">
            <Link to="/green-roads/">
              <Trans i18nKey="shippers.green-roads.discover-green-road-approach" />
            </Link>
          </p>
        </div>
      </div>
      <div className="fl-grid fl-three-cards" style={{ marginTop: "72px", marginBottom: "104px" }}>
        <section>
          <MesureIcon />
          <h3>
            <code>
              <Trans i18nKey="shippers.green-roads.three-cards.first.number" />
            </code>
            <Trans i18nKey="shippers.green-roads.three-cards.first.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.green-roads.three-cards.first.content" />
          </p>
        </section>
        <section>
          <ReduceIcon />
          <h3>
            <code>
              <Trans i18nKey="shippers.green-roads.three-cards.second.number" />
            </code>
            <Trans i18nKey="shippers.green-roads.three-cards.second.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.green-roads.three-cards.second.content" />
          </p>
        </section>
        <section>
          <CompensateIcon />
          <h3>
            <code>
              <Trans i18nKey="shippers.green-roads.three-cards.third.number" />
            </code>
            <Trans i18nKey="shippers.green-roads.three-cards.third.title" />
          </h3>
          <p>
            <Trans i18nKey="shippers.green-roads.three-cards.third.content" />
          </p>
        </section>
      </div>

      {/*

      <div className="fl-grid">
        <div className="fl-both-half">
          <h2>
            <Trans i18nKey="shippers.how-it-works.title" />
          </h2>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div
          className="fl-both-half fl-how-its-done-block fl-wip"
          style={{
            height: "910px",
            fontSize: "160px",
          }}>
          TODO
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "24px" }}>
        <div
          className="fl-both-half fl-how-its-done-block fl-wip"
          style={{
            height: "183px",
          }}>
          TODO
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "24px" }}>
        <div
          className="fl-both-half fl-how-its-done-block fl-wip"
          style={{
            height: "183px",
          }}>
          TODO
        </div>
      </div>

      */}

      <div className="fl-grid" style={{ marginTop: "88px" }}>
        <div className="fl-blue-block fl-grid fl-blue-context">
          <div className="fl-bg-bubble" style={{ right: "50%", bottom: "-274px" }} />
          {/*
          <div className="fl-first-half fl-people-grid">
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">FMCG</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">Food &amp; Bev</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">Retail</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">Industry</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">Public works</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
            <div className="fl-person">
              <div className="fl-placeholder" />
              <div className="fl-tag fl-naked-tag">Agro</div>
              <p className="fl-person-role">Pharetra fusce consectetur</p>
            </div>
          </div>
          */}
          <div className="fl-first-half fl-vertical-center fl-resp-md-hide">
            <img
              src={fretlinkDeskFromTop}
              alt=""
              style={{
                width: 488,
                height: 455,
                objectFit: "cover",
                borderRadius: 8,
                boxShadow: "0 0 25px -5px rgb(0, 18, 36)",
              }}
            />
          </div>
          <div className="fl-second-half fl-resp-md-full-width">
            <FretlinkLogo />
            <div className="fl-medium-block" style={{ marginTop: "45px" }}>
              <h2>
                <Trans i18nKey="shippers.contact-us.title" />
              </h2>
              <Trans parent="p" i18nKey="shippers.contact-us.p-we-start-all-projects" />
              <p className="fl-link-next">
                <Link to="/sign-up/shipper/">
                  <Trans i18nKey="shippers.contact-us.leave-us-our-coordinates" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShippersPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
