import React from "react";

export default function FourTrucks() {
  return (
    <svg width="579" height="663" viewBox="0 0 579 663">
      <g fill="none" fillRule="evenodd">
        <g id="four-trucks-dump-truck">
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M409.64 147.2l.01-9.66m-68.21 54.43l45.76-26.42"></path>
          <path fill="#001428" d="M.38.53v5.06" transform="translate(335.64 182.53)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M336.02 183.06v5.06"></path>
          <path
            fill="#000F1F"
            d="M201.23 98.1c-5.42 3.16-9.84 10.78-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.11 9.87-10.77 9.87-17.03 0-6.29-4.4-8.83-9.87-5.7"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M452.58 120.91c-5.42 3.16-9.84 10.79-9.84 17.06 0 6.28 4.42 8.82 9.84 5.67 5.48-3.12 9.87-10.77 9.87-17.04 0-6.28-4.4-8.82-9.87-5.69z"></path>
          <path
            fill="#000F1F"
            d="M199.1 92.15c-1.77-.94-4.17-.77-6.83.74-5.45 3.15-9.85 10.8-9.85 17.08 0 3.02 1.02 5.16 2.68 6.25l8.95 5.17c-1.64-1.07-2.66-3.24-2.66-6.24 0-6.27 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23h-.01z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M450.45 114.97c-1.76-.94-4.17-.77-6.83.74-5.44 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.25l8.96 5.17c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.27 4.42-13.9 9.84-17.06 2.72-1.54 5.14-1.7 6.91-.72l-9.03-5.22h-.01z"></path>
          <path
            fill="#000F1F"
            d="M201.23 101.4c-3.84 2.23-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.22 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.03"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M452.58 124.22c-3.84 2.23-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.23 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04z"></path>
          <path
            fill="#000F1F"
            d="M201.23 106.97a4.8 4.8 0 00-2.13 3.75c0 1.38.95 1.94 2.13 1.23a4.74 4.74 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.25"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M452.58 129.78a4.8 4.8 0 00-2.13 3.76c0 1.38.95 1.93 2.13 1.23a4.74 4.74 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.26z"></path>
          <path
            fill="#000F1F"
            d="M177.11 112.04c-5.41 3.16-9.84 10.77-9.84 17.05 0 6.29 4.43 8.82 9.84 5.67 5.47-3.12 9.87-10.77 9.87-17.03 0-6.28-4.4-8.85-9.87-5.7"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M428.46 134.85c-5.41 3.16-9.84 10.78-9.84 17.05 0 6.3 4.43 8.83 9.84 5.68 5.48-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.85-9.87-5.7z"></path>
          <path
            fill="#000F1F"
            d="M174.98 106.1c-1.76-.96-4.17-.79-6.83.73-5.44 3.15-9.86 10.8-9.86 17.07 0 3.02 1.03 5.17 2.69 6.25l8.96 5.18c-1.65-1.08-2.67-3.24-2.67-6.24 0-6.28 4.43-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.73L175 106.1h-.01z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M426.33 128.92c-1.76-.96-4.17-.79-6.83.73-5.44 3.15-9.85 10.8-9.85 17.07 0 3.02 1.02 5.17 2.68 6.25l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.25 0-6.27 4.43-13.89 9.84-17.05 2.72-1.54 5.15-1.7 6.91-.72l-9.03-5.21h0z"></path>
          <path
            fill="#000F1F"
            d="M177.11 115.34c-3.84 2.24-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.03 3.88-2.22 7-7.64 7-12.1 0-4.45-3.12-6.24-7-4.03"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M428.46 138.16c-3.84 2.24-6.96 7.65-6.96 12.09 0 4.46 3.12 6.25 6.96 4.04 3.88-2.22 7-7.64 7-12.1 0-4.45-3.12-6.24-7-4.03z"></path>
          <path
            fill="#000F1F"
            d="M177.11 120.9a4.8 4.8 0 00-2.13 3.75c0 1.4.96 1.94 2.13 1.24a4.74 4.74 0 002.17-3.74c0-1.35-.97-1.92-2.17-1.24"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M428.46 143.72a4.8 4.8 0 00-2.13 3.75c0 1.39.96 1.94 2.13 1.24a4.74 4.74 0 002.17-3.74c0-1.35-.97-1.92-2.17-1.25z"></path>
          <path
            fill="#000F1F"
            d="M153.64 126.42c-5.42 3.16-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.11 9.86-10.76 9.86-17.04 0-6.28-4.39-8.83-9.86-5.68"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M405 149.24c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.83 5.67 5.47-3.12 9.87-10.76 9.87-17.04 0-6.28-4.4-8.83-9.87-5.68z"></path>
          <path
            fill="#000F1F"
            d="M151.5 120.48c-1.75-.96-4.16-.78-6.82.73-5.45 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.17 2.68 6.24l8.96 5.19c-1.65-1.08-2.67-3.25-2.67-6.25 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.73l-9.03-5.21h-.02z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M402.85 143.3c-1.75-.96-4.16-.78-6.82.73-5.45 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.17 2.68 6.24l8.96 5.19c-1.64-1.09-2.67-3.25-2.67-6.25 0-6.28 4.43-13.9 9.84-17.05 2.73-1.55 5.15-1.7 6.92-.73l-9.04-5.22h-.02z"></path>
          <path
            fill="#000F1F"
            d="M153.64 129.72c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.46 3.12 6.26 6.96 4.04 3.88-2.23 7-7.64 7-12.11 0-4.45-3.12-6.24-7-4.03"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M405 152.54c-3.85 2.23-6.97 7.65-6.97 12.09 0 4.46 3.12 6.27 6.96 4.05 3.88-2.23 7-7.64 7-12.12 0-4.45-3.12-6.23-7-4.02z"></path>
          <path
            fill="#000F1F"
            d="M153.64 135.29a4.8 4.8 0 00-2.14 3.74c0 1.4.96 1.95 2.14 1.25a4.77 4.77 0 002.17-3.75c0-1.34-.98-1.91-2.17-1.24"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M405 158.1a4.8 4.8 0 00-2.15 3.75c0 1.39.96 1.94 2.14 1.25a4.77 4.77 0 002.17-3.75c0-1.34-.97-1.92-2.17-1.25z"></path>
          <path
            fill="#000F1F"
            d="M81.38 168.14c-5.42 3.16-9.85 10.77-9.85 17.05 0 6.3 4.43 8.82 9.85 5.68 5.47-3.12 9.86-10.77 9.86-17.04s-4.4-8.84-9.86-5.69"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M332.73 190.96c-5.42 3.15-9.85 10.77-9.85 17.05 0 6.29 4.43 8.82 9.85 5.68 5.47-3.13 9.86-10.77 9.86-17.04s-4.39-8.84-9.86-5.69z"></path>
          <path
            fill="#000F1F"
            d="M79.24 162.2c-1.76-.95-4.17-.78-6.83.73-5.45 3.16-9.85 10.8-9.85 17.09 0 3 1.02 5.15 2.68 6.25l8.96 5.17c-1.64-1.08-2.67-3.24-2.67-6.25 0-6.28 4.43-13.9 9.85-17.05 2.71-1.54 5.13-1.7 6.9-.72l-9.03-5.22h-.01z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M330.59 185.02c-1.76-.95-4.17-.78-6.83.73-5.45 3.16-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.26l8.96 5.17c-1.64-1.09-2.67-3.25-2.67-6.25 0-6.28 4.43-13.9 9.85-17.05 2.71-1.54 5.13-1.7 6.9-.73l-9.03-5.21h-.01z"></path>
          <path
            fill="#000F1F"
            d="M81.38 171.45c-3.85 2.23-6.97 7.65-6.97 12.09 0 4.46 3.12 6.26 6.97 4.04 3.87-2.22 7-7.64 7-12.1s-3.13-6.25-7-4.03"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M332.73 194.26c-3.85 2.24-6.97 7.66-6.97 12.1 0 4.45 3.12 6.26 6.97 4.04 3.87-2.22 7-7.64 7-12.1s-3.13-6.25-7-4.04z"></path>
          <path
            fill="#000F1F"
            d="M81.38 177a4.83 4.83 0 00-2.14 3.76c0 1.38.96 1.94 2.14 1.24a4.76 4.76 0 002.17-3.74c0-1.36-.98-1.92-2.17-1.25"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M332.73 199.83a4.83 4.83 0 00-2.14 3.75c0 1.38.96 1.94 2.14 1.24a4.76 4.76 0 002.17-3.74c0-1.36-.98-1.93-2.17-1.25z"></path>
          <path
            fill="#000F1F"
            d="M.01 58.8L101.62.18l58.12 33.51-101.6 58.63z"
            transform="translate(309.66 22.82)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M309.67 81.62L411.27 23l58.13 33.5"></path>
          <path
            fill="#001428"
            d="M54.81 6.38l-10-5.77L.52 26.08"
            transform="translate(357.2 79.22)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M412 85.6l-10-5.77-44.29 25.47"></path>
          <path
            fill="#000F1F"
            d="M32.94 13.69L9.46.14.45 20.89l10 5.77"
            transform="translate(401.55 58.94)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M434.5 72.63L411 59.08l-9 20.75 10 5.77"></path>
          <path fill="#001428" d="M.58.55L52 29.89" transform="translate(410.43 26.62)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M411 27.17l51.43 29.34"></path>
          <path
            fill="#000F1F"
            d="M183.14 49.81l-23.48-13.54V4.35l51.41 29.34z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M434.5 72.63L411 59.09V27.17l51.41 29.34z"></path>
          <path
            fill="#000F1F"
            d="M116.45 139.78l88.82-51.26 12.78-22.19V33.69l-101.6 58.63z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M367.8 162.6l88.82-51.26 12.78-22.2V56.52l-101.6 58.63zm94.62-106.1l-94.62 54.63-51.12-29.51 94.33-54.45"></path>
          <path
            fill="#000F1F"
            d="M58.32 106.26l58.13 33.52V92.32L58.32 58.8z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M309.67 129.08l58.13 33.52v-47.46l-58.13-33.52z"></path>
          <path
            fill="#001428"
            d="M54.28 33.41L.6 2.42v12.42c0 3.62 1.93 6.97 5.07 8.78l44 25.4a10.14 10.14 0 009.41.39l8.5-4.03v-9.3c0-1.8 2.85-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.17 3.53 1.4l12.73-7.34V.12L75 16.17l-5.97 8.72-14.75 8.52"
            transform="translate(251.35 169.85)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M305.63 203.26l-53.68-30.99v12.42c0 3.63 1.93 6.97 5.07 8.79l44 25.4c2.89 1.66 6.4 1.8 9.41.38l8.5-4.03v-9.3c0-1.8 2.86-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.18 3.54 1.4l12.73-7.34v-6.04l-27.79 16.04-5.97 8.72-14.75 8.52"></path>
          <path
            fill="#000F1F"
            d="M16.48 171.56l22.13 12.79 1.78-9.77-25.72-15.03z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M267.83 194.38l22.13 12.79 1.78-9.77-25.72-15.03z"></path>
          <path
            fill="#000F1F"
            d="M41.83 180.06l11.07 6.39v-4.72l-11.07-6.4z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M293.18 202.88l11.07 6.39v-4.72l-11.07-6.4z"></path>
          <path
            fill="#000F1F"
            d="M1.9 157l11.07 6.39v-4.71L1.9 152.29z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M253.25 179.82l11.07 6.39v-4.71l-11.07-6.4z"></path>
          <path
            fill="#001224"
            d="M4.31 111.18l-1.12 15.88 53.68 30.98 1.21-17.48-52.74-30.21z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M255.66 134l-1.12 15.88 53.68 30.98 1.21-17.48-52.74-30.21z"></path>
          <path
            fill="#001224"
            d="M4.05 111.18a32.27 32.27 0 0116.14-27.95l28.08-16.21L101.95 98l-28.73 16.58a33.28 33.28 0 00-16.58 26.8L4.05 111.19z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M255.4 134h0a32.27 32.27 0 0116.14-27.95l28.08-16.21 53.68 30.99-28.73 16.58A33.28 33.28 0 00308 164.2L255.4 134z"></path>
          <path
            fill="#000F1F"
            d="M1.9 128.04L.6 149.46l53.68 30.98 1.29-21.41z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M253.25 150.86l-1.3 21.42 53.68 30.98 1.3-21.41z"></path>
          <path
            fill="#001224"
            d="M56.64 141.4l31.06-17.94c.84-.49 1.9.12 1.9 1.1l.02 3.6c.01 3.18-1.48 6.2-4.02 8.12l-30.03 22.75-1.3 21.41 14.76-8.52L75 163.2l27.79-16.04-.84-49.15-28.73 16.58a33.28 33.28 0 00-16.58 26.8"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M308 164.22h0l31.05-17.94c.84-.49 1.9.12 1.9 1.09l.02 3.6c.01 3.19-1.47 6.2-4.02 8.13l-30.02 22.75-1.3 21.41 14.75-8.52 5.97-8.72 27.79-16.04-.84-49.15-28.73 16.58A33.28 33.28 0 00308 164.2z"></path>
          <path
            fill="#000F1F"
            d="M1.9 128.04l53.67 30.99 1.3-.99-53.68-30.98z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M253.25 150.86l53.67 30.99 1.3-1-53.68-30.97z"></path>
          <path
            fill="#000F1F"
            d="M56.87 158.04l28.73-21.76a10.14 10.14 0 004.02-8.13l-.02-3.6c0-.97-1.06-1.58-1.9-1.1l-29.62 17.12-1.21 17.47z"
            transform="translate(251.35 22.82)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M308.22 180.86l28.73-21.76a10.14 10.14 0 004.02-8.13l-.02-3.6c0-.97-1.06-1.58-1.9-1.1l-29.62 17.11-1.21 17.48zm-4.42 6.22l-48.53-28.02m48.33 31.78l-48.54-28.03m48.35 31.77l-48.54-28.02"></path>
        </g>
        <g id="four-trucks-regular-trucks" transform="translate(0 191.1)">
          <g id="four-trucks-left-truck" transform="translate(.34 30.66)">
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M158.01 218.18v-4M89.8 262.95l45.77-26.42"></path>
            <path fill="#000F1F" d="M84.34 259.1v-5.07 5.06z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M84.34 254.03v5.06"></path>
            <path
              fill="#000F1F"
              d="M153.36 220.22c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.12 9.86-10.76 9.86-17.05 0-6.27-4.39-8.82-9.86-5.67"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M153.36 220.22c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.12 9.86-10.76 9.86-17.05 0-6.27-4.39-8.82-9.86-5.67z"></path>
            <path
              fill="#000F1F"
              d="M151.22 214.27c-1.75-.95-4.16-.78-6.82.74-5.45 3.15-9.86 10.8-9.86 17.07 0 3.02 1.03 5.17 2.69 6.25l8.96 5.18c-1.65-1.08-2.67-3.24-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.56 5.14-1.7 6.91-.74l-9.03-5.2h-.02z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M151.22 214.27c-1.75-.95-4.16-.78-6.82.74-5.45 3.15-9.86 10.8-9.86 17.07 0 3.02 1.03 5.17 2.69 6.25l8.96 5.18c-1.65-1.08-2.67-3.24-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.56 5.14-1.7 6.91-.74l-9.03-5.2h-.02z"></path>
            <path
              fill="#000F1F"
              d="M153.36 223.51c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.46 3.12 6.26 6.96 4.05 3.88-2.24 7-7.64 7-12.12 0-4.45-3.12-6.24-7-4.03"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M153.36 223.51c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.46 3.12 6.26 6.96 4.05 3.88-2.24 7-7.64 7-12.12 0-4.45-3.12-6.24-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M153.36 229.08a4.8 4.8 0 00-2.14 3.75c0 1.39.96 1.94 2.14 1.24a4.77 4.77 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M153.36 229.08a4.8 4.8 0 00-2.14 3.75c0 1.39.96 1.94 2.14 1.24a4.77 4.77 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25z"></path>
            <path
              fill="#000F1F"
              d="M303.42 133.58c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.68 5.47-3.13 9.87-10.78 9.87-17.04 0-6.29-4.4-8.84-9.87-5.69"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M303.42 133.58c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.68 5.47-3.13 9.87-10.78 9.87-17.04 0-6.29-4.4-8.84-9.87-5.69z"></path>
            <path
              fill="#000F1F"
              d="M301.29 127.63c-1.77-.94-4.17-.78-6.83.74-5.45 3.15-9.85 10.8-9.85 17.08 0 3 1.02 5.16 2.68 6.24l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23h-.01z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M301.29 127.63c-1.77-.94-4.17-.78-6.83.74-5.45 3.15-9.85 10.8-9.85 17.08 0 3 1.02 5.16 2.68 6.24l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23h-.01z"></path>
            <path
              fill="#000F1F"
              d="M303.42 136.88c-3.84 2.24-6.97 7.65-6.97 12.1 0 4.45 3.13 6.24 6.97 4.04 3.88-2.22 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M303.42 136.88c-3.84 2.24-6.97 7.65-6.97 12.1 0 4.45 3.13 6.24 6.97 4.04 3.88-2.22 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04z"></path>
            <path
              fill="#000F1F"
              d="M303.42 142.45a4.8 4.8 0 00-2.13 3.75c0 1.38.95 1.94 2.13 1.24a4.78 4.78 0 002.17-3.75c0-1.34-.98-1.91-2.17-1.24"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M303.42 142.45a4.8 4.8 0 00-2.13 3.75c0 1.38.95 1.94 2.13 1.24a4.78 4.78 0 002.17-3.75c0-1.34-.98-1.91-2.17-1.24z"></path>
            <path
              fill="#000F1F"
              d="M279.57 147.34c-5.42 3.16-9.84 10.79-9.84 17.06 0 6.28 4.42 8.82 9.84 5.67 5.48-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.83-9.87-5.7"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M279.57 147.34c-5.42 3.16-9.84 10.79-9.84 17.06 0 6.28 4.42 8.82 9.84 5.67 5.48-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.83-9.87-5.7z"></path>
            <path
              fill="#000F1F"
              d="M277.44 141.4c-1.76-.94-4.17-.77-6.83.74-5.44 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.25l8.96 5.17c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.27 4.42-13.9 9.84-17.06 2.72-1.54 5.14-1.7 6.91-.72l-9.03-5.22h-.01z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M277.44 141.4c-1.76-.94-4.17-.77-6.83.74-5.44 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.25l8.96 5.17c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.27 4.42-13.9 9.84-17.06 2.72-1.54 5.14-1.7 6.91-.72l-9.03-5.22h-.01z"></path>
            <path
              fill="#000F1F"
              d="M279.57 150.65c-3.84 2.23-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.23 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M279.57 150.65c-3.84 2.23-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.23 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04z"></path>
            <path
              fill="#000F1F"
              d="M279.57 156.21a4.8 4.8 0 00-2.13 3.76c0 1.38.95 1.94 2.13 1.23a4.75 4.75 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.26"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M279.57 156.21a4.8 4.8 0 00-2.13 3.76c0 1.38.95 1.94 2.13 1.23a4.75 4.75 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.26z"></path>
            <path
              fill="#000F1F"
              d="M255.45 161.29c-5.41 3.15-9.84 10.77-9.84 17.04 0 6.3 4.43 8.83 9.84 5.68 5.48-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.85-9.87-5.7"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M255.45 161.29c-5.41 3.15-9.84 10.77-9.84 17.04 0 6.3 4.43 8.83 9.84 5.68 5.48-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.85-9.87-5.7z"></path>
            <path
              fill="#000F1F"
              d="M253.32 155.35c-1.76-.96-4.17-.79-6.83.73-5.44 3.15-9.85 10.8-9.85 17.07 0 3.02 1.02 5.17 2.68 6.25l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.25 0-6.27 4.43-13.89 9.84-17.04 2.72-1.55 5.15-1.7 6.91-.73l-9.03-5.21z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M253.32 155.35c-1.76-.96-4.17-.79-6.83.73-5.44 3.15-9.85 10.8-9.85 17.07 0 3.02 1.02 5.17 2.68 6.25l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.25 0-6.27 4.43-13.89 9.84-17.04 2.72-1.55 5.15-1.7 6.91-.73l-9.03-5.21h0z"></path>
            <path
              fill="#000F1F"
              d="M255.45 164.59c-3.84 2.24-6.96 7.65-6.96 12.09 0 4.46 3.12 6.25 6.96 4.04 3.88-2.22 7-7.64 7-12.1 0-4.45-3.12-6.24-7-4.03"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M255.45 164.59c-3.84 2.24-6.96 7.65-6.96 12.09 0 4.46 3.12 6.25 6.96 4.04 3.88-2.22 7-7.64 7-12.1 0-4.45-3.12-6.24-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M255.45 170.16a4.8 4.8 0 00-2.13 3.74c0 1.39.96 1.94 2.13 1.24a4.75 4.75 0 002.18-3.74c0-1.35-.98-1.92-2.18-1.24"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M255.45 170.16a4.8 4.8 0 00-2.13 3.74c0 1.39.96 1.94 2.13 1.24a4.75 4.75 0 002.18-3.74c0-1.35-.98-1.92-2.18-1.24z"></path>
            <path
              fill="#000F1F"
              d="M81.1 261.94c-5.42 3.15-9.85 10.77-9.85 17.05 0 6.29 4.43 8.82 9.84 5.67 5.48-3.12 9.87-10.76 9.87-17.04 0-6.27-4.4-8.83-9.87-5.68"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M81.1 261.94c-5.42 3.15-9.85 10.77-9.85 17.05 0 6.29 4.43 8.82 9.84 5.67 5.48-3.12 9.87-10.76 9.87-17.04 0-6.27-4.4-8.83-9.87-5.68z"></path>
            <path
              fill="#000F1F"
              d="M78.96 256c-1.77-.96-4.17-.79-6.83.73-5.45 3.16-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.26l8.96 5.16c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.28 4.43-13.9 9.84-17.05 2.72-1.54 5.14-1.7 6.91-.73L78.97 256h-.01z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M78.96 256c-1.77-.96-4.17-.79-6.83.73-5.45 3.16-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.26l8.96 5.16c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.28 4.43-13.9 9.84-17.05 2.72-1.54 5.14-1.7 6.91-.73L78.97 256h-.01z"></path>
            <path
              fill="#000F1F"
              d="M81.1 265.24c-3.85 2.24-6.97 7.66-6.97 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.22 7-7.65 7-12.11 0-4.46-3.12-6.25-7-4.03"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M81.1 265.24c-3.85 2.24-6.97 7.66-6.97 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.22 7-7.65 7-12.11 0-4.46-3.12-6.25-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M81.1 270.8a4.82 4.82 0 00-2.14 3.75c0 1.39.95 1.94 2.13 1.24a4.76 4.76 0 002.18-3.73c0-1.36-.98-1.93-2.18-1.26"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M81.1 270.8a4.82 4.82 0 00-2.14 3.75c0 1.39.95 1.94 2.13 1.24a4.76 4.76 0 002.18-3.73c0-1.36-.98-1.93-2.18-1.26z"></path>
            <path
              fill="#000F1F"
              d="M47.26 99.44l6.63 3.84V31.35L.22.35v29.73"
              transform="translate(55.14 134.99)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M102.4 234.43l6.63 3.84v-71.93l-53.67-31v29.73"></path>
            <path fill="#001224" d="M55.36 135.34l53.67 31L343.46 30.99 289.78 0z"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M55.36 135.34l53.67 31L343.46 30.99 289.78 0z"></path>
            <path
              fill="#001428"
              d="M.43 4.81L7 8.6V4.41L.36.57"
              transform="translate(102.04 233.86)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M102.47 238.67l6.57 3.79v-4.2l-6.64-3.83"></path>
            <path
              fill="#001224"
              stroke="#288CFF"
              strokeWidth="0.71"
              d="M109.03 238.27l234.43-135.36V30.99L109.03 166.34z"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M109.03 238.27l234.43-135.36V30.99L109.03 166.34z"></path>
            <path fill="#000F1F" d="M109.03 242.46l234.43-135.35v-4.2L109.03 238.27z"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M109.03 242.46l234.43-135.35v-4.2L109.03 238.27z"></path>
            <path
              fill="#001428"
              d="M54 33.41L.32 2.42v12.42c0 3.62 1.93 6.97 5.07 8.78l44 25.4a10.14 10.14 0 009.41.39l8.5-4.03v-9.3c0-1.8 2.85-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.17 3.53 1.4l12.73-7.34V.12L74.72 16.17l-5.97 8.72L54 33.4"
              transform="translate(0 240.83)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M54 274.24L.32 243.25v12.42c0 3.62 1.93 6.97 5.07 8.78l44 25.4a10.14 10.14 0 009.41.39l8.5-4.04v-9.29c0-1.8 2.85-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.17 3.53 1.4l12.73-7.35v-6.04L74.72 257l-5.97 8.72L54 274.24"></path>
            <path fill="#000F1F" d="M16.2 265.35l22.13 12.8 1.78-9.78-25.73-15.03z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M16.2 265.35l22.13 12.8 1.78-9.78-25.73-15.03z"></path>
            <path fill="#000F1F" d="M41.55 273.85l11.07 6.39v-4.72l-11.07-6.39z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M41.55 273.85l11.07 6.39v-4.72l-11.07-6.39z"></path>
            <path fill="#000F1F" d="M1.62 250.79l11.07 6.39v-4.71l-11.07-6.39z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M1.62 250.79l11.07 6.39v-4.71l-11.07-6.39z"></path>
            <path
              fill="#001224"
              d="M4.02 204.97l-1.11 15.88 53.68 30.98 1.21-17.47-52.74-30.21z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M4.02 204.97l-1.11 15.88 53.68 30.98 1.21-17.47-52.74-30.21z"></path>
            <path
              fill="#001224"
              d="M3.77 204.97a32.27 32.27 0 0116.13-27.94L48 160.8l53.67 31-28.73 16.58a33.28 33.28 0 00-16.58 26.8l-52.6-30.22z"></path>
            <path fill="#000F1F" d="M1.62 221.84l-1.3 21.41L54 274.24l1.29-21.41z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M1.62 221.84l-1.3 21.41L54 274.24l1.29-21.41z"></path>
            <path
              fill="#001224"
              d="M56.36 235.2l31.06-17.95c.84-.48 1.9.12 1.9 1.1l.02 3.6c.01 3.19-1.48 6.2-4.02 8.12l-30.03 22.76-1.3 21.4 14.76-8.51 5.97-8.72 27.78-16.05-.83-49.15-28.73 16.59a33.28 33.28 0 00-16.58 26.8"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M56.36 235.2h0l31.06-17.95c.84-.48 1.9.12 1.9 1.1l.02 3.6c.01 3.19-1.48 6.2-4.02 8.12l-30.03 22.76-1.3 21.4 14.76-8.51 5.97-8.72 27.78-16.05-.83-49.15-28.73 16.59a33.28 33.28 0 00-16.58 26.8z"></path>
            <path fill="#000F1F" d="M1.62 221.84l53.67 30.99 1.3-1-53.68-30.98z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M1.62 221.84l53.67 30.99 1.3-1-53.68-30.98z"></path>
            <path
              fill="#000F1F"
              d="M56.59 251.83l28.73-21.76a10.14 10.14 0 004.02-8.13l-.02-3.6c0-.97-1.06-1.57-1.9-1.09L57.8 234.36l-1.21 17.47z"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M56.59 251.83l28.73-21.76a10.14 10.14 0 004.02-8.13l-.02-3.6c0-.97-1.06-1.57-1.9-1.09L57.8 234.36l-1.21 17.47zm-4.42 6.23L3.64 230.04m48.33 31.77L3.43 233.79m48.34 31.76L3.24 237.54m.53-32.57h0a32.27 32.27 0 0116.13-27.94L48 160.8l53.67 31-28.73 16.58a33.28 33.28 0 00-16.58 26.8l-52.6-30.22z"></path>
            <path
              fill="#001224"
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M21.04 176.95c0-11.36 12.04-22.6 21.9-28.28l53.21 28.72c-9.55 5.5-22.1 19.48-22.76 30.47l-5.1-3.01L27.9 181l-6.85-4.05z"></path>
            <path
              fill="#000F1F"
              stroke="#FFF"
              strokeWidth="0.5"
              d="M101.55 191.72L73.2 207.86c1.14-11.6 8.74-21.76 22.78-30.47l5.56 14.33z"></path>
          </g>
          <g id="four-trucks-right-truck">
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M393.58 211.68v-9.67m-68.21 54.44l45.77-26.42"></path>
            <path fill="#001428" d="M.77.53v5.06" transform="translate(319.14 247.01)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M319.9 247.54v5.06"></path>
            <path
              fill="#000F1F"
              d="M153.45 213.25c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.12 9.86-10.76 9.86-17.04 0-6.28-4.39-8.83-9.86-5.68"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M388.93 213.72c-5.42 3.16-9.84 10.77-9.84 17.05 0 6.29 4.42 8.82 9.84 5.67 5.47-3.11 9.86-10.76 9.86-17.04 0-6.27-4.39-8.83-9.86-5.68z"></path>
            <path
              fill="#000F1F"
              d="M151.3 207.3c-1.75-.95-4.15-.77-6.81.74-5.45 3.15-9.86 10.8-9.86 17.07 0 3.02 1.03 5.17 2.69 6.25l8.95 5.19c-1.64-1.09-2.66-3.25-2.66-6.25 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.73l-9.03-5.22h-.02z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M386.79 207.78c-1.75-.96-4.16-.78-6.82.73-5.45 3.15-9.86 10.8-9.86 17.08 0 3.02 1.03 5.17 2.69 6.25l8.96 5.18c-1.65-1.08-2.67-3.24-2.67-6.25 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.73l-9.03-5.21h-.02z"></path>
            <path
              fill="#000F1F"
              d="M153.45 216.54c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.46 3.12 6.27 6.96 4.05 3.88-2.23 7-7.64 7-12.12 0-4.45-3.12-6.24-7-4.03"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M388.93 217.02c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.46 3.12 6.26 6.96 4.04 3.88-2.23 7-7.63 7-12.11 0-4.45-3.12-6.24-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M153.45 222.11a4.8 4.8 0 00-2.14 3.75c0 1.39.95 1.94 2.14 1.24a4.77 4.77 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M388.93 222.59a4.8 4.8 0 00-2.14 3.74c0 1.4.96 1.95 2.14 1.25a4.77 4.77 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25z"></path>
            <path
              fill="#000F1F"
              d="M303.5 126.61c-5.4 3.16-9.83 10.77-9.83 17.05 0 6.28 4.42 8.82 9.84 5.68 5.47-3.12 9.86-10.78 9.86-17.04 0-6.28-4.4-8.84-9.86-5.69"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M538.99 127.09c-5.42 3.15-9.84 10.77-9.84 17.05 0 6.28 4.42 8.81 9.84 5.67 5.47-3.12 9.87-10.77 9.87-17.04 0-6.28-4.4-8.83-9.87-5.68z"></path>
            <path
              fill="#000F1F"
              d="M301.37 120.66c-1.76-.94-4.16-.78-6.82.74-5.45 3.15-9.85 10.8-9.85 17.08 0 3 1.02 5.16 2.68 6.25l8.96 5.17c-1.64-1.07-2.67-3.23-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23h-.02z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M536.86 121.13c-1.77-.94-4.17-.77-6.83.75-5.45 3.15-9.85 10.8-9.85 17.07 0 3.01 1.02 5.17 2.68 6.25l8.96 5.18c-1.64-1.08-2.67-3.24-2.67-6.24 0-6.28 4.42-13.9 9.84-17.05 2.72-1.56 5.14-1.71 6.91-.73l-9.03-5.23h-.01z"></path>
            <path
              fill="#000F1F"
              d="M303.5 129.9c-3.83 2.25-6.96 7.66-6.96 12.1 0 4.47 3.13 6.26 6.97 4.05 3.87-2.22 7-7.65 7-12.1 0-4.47-3.13-6.25-7-4.04"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M538.99 130.38c-3.84 2.24-6.97 7.65-6.97 12.1 0 4.46 3.13 6.25 6.97 4.05 3.88-2.22 7-7.65 7-12.11 0-4.46-3.12-6.24-7-4.04z"></path>
            <path
              fill="#000F1F"
              d="M303.5 135.48a4.8 4.8 0 00-2.13 3.75c0 1.38.96 1.94 2.14 1.24a4.78 4.78 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M538.99 135.95a4.8 4.8 0 00-2.13 3.75c0 1.39.95 1.94 2.13 1.24a4.78 4.78 0 002.17-3.74c0-1.35-.98-1.92-2.17-1.25z"></path>
            <path
              fill="#000F1F"
              d="M279.66 140.38c-5.42 3.15-9.84 10.78-9.84 17.05 0 6.28 4.42 8.82 9.84 5.67 5.47-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.83-9.87-5.7"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M515.14 140.85c-5.42 3.16-9.84 10.78-9.84 17.06 0 6.28 4.42 8.82 9.84 5.67 5.48-3.12 9.87-10.77 9.87-17.04 0-6.28-4.4-8.83-9.87-5.69z"></path>
            <path
              fill="#000F1F"
              d="M277.53 134.43c-1.77-.94-4.17-.77-6.83.74-5.45 3.15-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.25l8.95 5.17c-1.64-1.08-2.66-3.24-2.66-6.24 0-6.27 4.42-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23h-.01z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M513.01 134.9c-1.76-.94-4.17-.77-6.83.74-5.44 3.16-9.85 10.8-9.85 17.08 0 3.02 1.02 5.17 2.68 6.26l8.96 5.17c-1.64-1.08-2.67-3.25-2.67-6.24 0-6.28 4.42-13.9 9.84-17.06 2.72-1.55 5.14-1.7 6.91-.72l-9.03-5.23H513z"></path>
            <path
              fill="#000F1F"
              d="M279.66 143.68c-3.84 2.23-6.96 7.65-6.96 12.1 0 4.45 3.12 6.25 6.96 4.04 3.88-2.22 7-7.65 7-12.1 0-4.47-3.12-6.25-7-4.04"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M515.14 144.16c-3.84 2.22-6.96 7.64-6.96 12.1 0 4.44 3.12 6.25 6.96 4.04 3.88-2.23 7-7.66 7-12.11 0-4.47-3.12-6.25-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M279.66 149.25a4.8 4.8 0 00-2.13 3.75c0 1.38.95 1.94 2.13 1.23a4.75 4.75 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.25"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M515.14 149.72a4.8 4.8 0 00-2.13 3.75c0 1.39.95 1.94 2.13 1.24a4.75 4.75 0 002.17-3.73c0-1.37-.97-1.94-2.17-1.26z"></path>
            <path
              fill="#000F1F"
              d="M255.54 154.32c-5.41 3.16-9.84 10.77-9.84 17.04 0 6.3 4.43 8.83 9.84 5.68 5.47-3.12 9.87-10.77 9.87-17.03 0-6.29-4.4-8.85-9.87-5.7"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M491.02 154.8c-5.41 3.15-9.84 10.77-9.84 17.04 0 6.29 4.43 8.83 9.84 5.68 5.48-3.13 9.87-10.77 9.87-17.04 0-6.28-4.4-8.84-9.87-5.69z"></path>
            <path
              fill="#000F1F"
              d="M253.41 148.38c-1.76-.96-4.17-.79-6.83.73-5.44 3.15-9.86 10.8-9.86 17.07 0 3.02 1.03 5.17 2.69 6.25l8.96 5.18c-1.65-1.08-2.67-3.24-2.67-6.24 0-6.28 4.43-13.9 9.84-17.05 2.72-1.55 5.14-1.7 6.91-.73l-9.03-5.21h-.01z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M488.9 148.85c-1.77-.95-4.18-.78-6.84.74-5.44 3.15-9.85 10.8-9.85 17.07 0 3.01 1.02 5.17 2.68 6.24l8.96 5.19c-1.64-1.09-2.67-3.25-2.67-6.25 0-6.27 4.43-13.9 9.84-17.05 2.72-1.55 5.15-1.7 6.91-.73l-9.03-5.2h0z"></path>
            <path
              fill="#000F1F"
              d="M255.54 157.62c-3.84 2.24-6.96 7.65-6.96 12.1 0 4.45 3.12 6.24 6.96 4.03 3.88-2.22 7-7.64 7-12.1 0-4.45-3.12-6.24-7-4.03"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M491.02 158.1c-3.84 2.23-6.96 7.64-6.96 12.09s3.12 6.25 6.96 4.04c3.88-2.23 7-7.65 7-12.1 0-4.46-3.12-6.25-7-4.04z"></path>
            <path
              fill="#000F1F"
              d="M255.54 163.19a4.8 4.8 0 00-2.13 3.74c0 1.4.96 1.94 2.13 1.24a4.74 4.74 0 002.17-3.74c0-1.35-.97-1.92-2.17-1.24"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M491.02 163.66a4.8 4.8 0 00-2.13 3.74c0 1.4.96 1.94 2.13 1.24a4.74 4.74 0 002.17-3.73c0-1.36-.97-1.93-2.17-1.25z"></path>
            <path
              fill="#000F1F"
              d="M81.18 254.97c-5.41 3.15-9.84 10.77-9.84 17.05 0 6.29 4.43 8.82 9.84 5.68 5.48-3.13 9.86-10.77 9.86-17.05 0-6.26-4.38-8.83-9.86-5.68"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M316.67 255.44c-5.42 3.16-9.85 10.77-9.85 17.05 0 6.3 4.43 8.82 9.85 5.68 5.47-3.12 9.86-10.77 9.86-17.04s-4.4-8.84-9.86-5.69z"></path>
            <path
              fill="#000F1F"
              d="M79.04 249.02c-1.76-.95-4.16-.77-6.82.74-5.45 3.16-9.85 10.8-9.85 17.08 0 3.01 1.02 5.16 2.68 6.26l8.96 5.17c-1.64-1.09-2.67-3.25-2.67-6.25 0-6.28 4.43-13.9 9.84-17.05 2.72-1.54 5.14-1.7 6.91-.73l-9.03-5.22h-.02z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M314.53 249.5c-1.77-.95-4.17-.78-6.83.73-5.45 3.16-9.85 10.8-9.85 17.09 0 3 1.02 5.15 2.68 6.25l8.96 5.17c-1.64-1.08-2.67-3.24-2.67-6.25 0-6.28 4.43-13.9 9.85-17.05 2.71-1.54 5.13-1.7 6.9-.72l-9.03-5.22h-.01z"></path>
            <path
              fill="#000F1F"
              d="M81.18 258.27c-3.84 2.24-6.96 7.66-6.96 12.1 0 4.45 3.12 6.26 6.96 4.04 3.88-2.22 7-7.65 7-12.11 0-4.46-3.12-6.25-7-4.03"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M316.67 258.75c-3.85 2.23-6.97 7.65-6.97 12.09 0 4.46 3.12 6.26 6.97 4.04 3.87-2.22 7-7.64 7-12.1s-3.13-6.25-7-4.03z"></path>
            <path
              fill="#000F1F"
              d="M81.18 263.83a4.82 4.82 0 00-2.14 3.75c0 1.39.96 1.94 2.14 1.25a4.76 4.76 0 002.17-3.74c0-1.36-.98-1.93-2.17-1.26"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M316.67 264.3a4.82 4.82 0 00-2.14 3.76c0 1.38.95 1.94 2.14 1.24a4.76 4.76 0 002.17-3.74c0-1.36-.98-1.92-2.17-1.25z"></path>
            <path
              fill="#000F1F"
              d="M75.47 204.81l38.47 22.22 15.39-18.62-53.86-13.45z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M310.95 205.28l38.47 22.22 15.4-18.62-53.87-13.45z"></path>
            <path
              fill="#000F1F"
              d="M202.74 183.15l-12.26-7.08v-7.63l15.09 5.08z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M438.22 183.62l-12.26-7.08v-7.63l15.1 5.08z"></path>
            <path
              fill="#000F1F"
              d="M113.94 227.03l88.85-51.28-.05 7.4 126.61-73.21V92.86L113.94 217.17z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M349.42 227.5l88.85-51.28-.05 7.4 126.61-73.2v-17.1l-215.4 124.31z"></path>
            <path
              fill="#000F1F"
              d="M113.94 217.17L329.35 92.86l-38.47-22.21L75.47 194.96z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M349.42 217.64l215.41-124.3-38.47-22.22-215.4 124.31z"></path>
            <path
              fill="#000F1F"
              d="M287.58 2.82c13-7.51 33.68 3.93 46.17 25.56 12.5 21.63 12.07 45.26-.95 52.78-13.02 7.51-33.7-3.93-46.19-25.57-12.49-21.62-12.06-45.26.97-52.77"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M523.06 3.3c13-7.52 33.68 3.93 46.17 25.56 12.5 21.62 12.07 45.26-.95 52.77-13.02 7.52-33.7-3.93-46.18-25.56-12.5-21.63-12.06-45.26.96-52.78z"></path>
            <path
              fill="#001224"
              d="M48.04 208.36L260.68 85.52c6.13-3.53 9.88-11.07 9.9-21.9 0-21.56-15.13-47.75-33.79-58.54-9.36-5.34-17.76-5.87-23.9-2.33L.24 125.59"
              transform="translate(305.2 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M353.24 208.83L565.87 86c6.13-3.54 9.88-11.08 9.91-21.9 0-21.57-15.13-47.75-33.8-58.54-9.35-5.34-17.76-5.88-23.89-2.34L305.44 126.06"></path>
            <path
              fill="#000F1F"
              d="M69.82 125.67c-.27.16-.92.58-1.8 1.26l-.38.28a30.67 30.67 0 00-10.95 17.8l-.05.22c-.17.83-.3 1.7-.4 2.6-.04.3-.09.62-.12.94l-.05.4.02.05c-.86 10.07 2.08 22.52 8.93 34.38 6.84 11.87 16.16 20.63 25.31 24.92 0 .02.04.07.04.05l.42.18.72.3c.89.4 1.76.74 2.63 1.02l.05.02c9.32 3.11 17.2.96 21.02-.58l.3-.12a20.6 20.6 0 002.14-1c6.15-3.52 9.96-11.1 9.96-21.9.01-21.57-15.12-47.76-33.79-58.55-9.4-5.4-17.88-5.87-24-2.27"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#288CFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M305.3 126.15c-.26.15-.92.57-1.8 1.25l-.38.28a30.67 30.67 0 00-10.95 17.8l-.05.23c-.17.83-.3 1.7-.4 2.6-.04.3-.09.61-.11.93l-.06.4.03.06c-.87 10.07 2.07 22.51 8.92 34.38 6.84 11.86 16.16 20.63 25.32 24.92 0 .02.03.06.03.05.14.07.28.1.42.18l.72.3c.89.39 1.76.73 2.63 1.02l.05.02c9.32 3.1 17.2.95 21.02-.58l.3-.12a20.6 20.6 0 002.14-1.01c6.15-3.52 9.96-11.1 9.97-21.9 0-21.56-15.12-47.75-33.8-58.54-9.4-5.4-17.88-5.88-24-2.27z"></path>
            <path
              fill="#001428"
              d="M54.08 33.41L.41 2.42v12.42c0 3.62 1.93 6.97 5.07 8.78l44 25.4a10.14 10.14 0 009.41.39l8.5-4.03v-9.3c0-1.8 2.85-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.17 3.53 1.4l12.73-7.34V.12L74.81 16.17l-5.97 8.72-14.76 8.52"
              transform="translate(235.48 234.33)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M289.57 267.74l-53.68-30.98v12.42c0 3.62 1.93 6.97 5.07 8.78l44 25.4c2.88 1.67 6.4 1.8 9.41.38l8.5-4.03v-9.3c0-1.79 2.85-13.34 8.02-17.67l10.92-6.3c2.4-1.28 3.2.18 3.53 1.4l12.73-7.34v-6.04L310.3 250.5l-5.97 8.72-14.75 8.52"></path>
            <path
              fill="#000F1F"
              d="M16.28 258.39l22.14 12.79 1.78-9.78-25.73-15.02z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M251.76 258.86l22.14 12.8 1.78-9.79-25.73-15.02z"></path>
            <path
              fill="#000F1F"
              d="M41.64 266.89l11.07 6.38v-4.72l-11.07-6.39z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M277.12 267.36l11.07 6.38v-4.72l-11.07-6.39z"></path>
            <path
              fill="#000F1F"
              d="M1.71 243.83l11.07 6.38v-4.71l-11.07-6.39z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M237.2 244.3l11.06 6.38v-4.7l-11.07-6.4z"></path>
            <path
              fill="#001224"
              d="M4.11 198.01L3 213.88l53.68 30.99 1.21-17.48-52.74-30.21z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M239.6 198.48l-1.12 15.87 53.68 31 1.21-17.49-52.74-30.2z"></path>
            <path
              fill="#001224"
              d="M3.86 198a32.27 32.27 0 0116.13-27.94l28.09-16.21 53.68 30.99-28.73 16.58a33.28 33.28 0 00-16.58 26.8l-52.6-30.21z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M239.34 198.48h0a32.27 32.27 0 0116.13-27.95l28.1-16.21 53.67 30.99-28.73 16.58a33.28 33.28 0 00-16.58 26.8l-52.6-30.21z"></path>
            <path
              fill="#000F1F"
              d="M1.71 214.87l-1.3 21.41 53.67 30.99 1.3-21.41z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M237.2 215.34l-1.3 21.41 53.66 31 1.3-21.42z"></path>
            <path
              fill="#001224"
              d="M56.45 228.22L87.5 210.3c.84-.5 1.9.11 1.9 1.09l.01 3.6c.02 3.19-1.47 6.2-4.01 8.13l-30.03 22.75-1.3 21.41 14.76-8.52 5.97-8.72L102.58 234l-.84-49.15-28.72 16.58a33.28 33.28 0 00-16.58 26.8"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M291.93 228.7h0l31.06-17.94c.84-.49 1.9.12 1.9 1.1l.02 3.6c.01 3.18-1.48 6.2-4.02 8.12l-30.03 22.75-1.3 21.42 14.76-8.53 5.97-8.72 27.78-16.04-.83-49.15-28.73 16.58a33.28 33.28 0 00-16.58 26.8z"></path>
            <path
              fill="#000F1F"
              d="M1.71 214.87l53.67 30.99 1.3-.99L3 213.88z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M237.2 215.34l53.66 31 1.3-1-53.68-30.99z"></path>
            <path
              fill="#000F1F"
              d="M56.68 244.87L85.4 223.1a10.14 10.14 0 004.01-8.13l-.01-3.6c0-.98-1.06-1.58-1.9-1.1L57.89 227.4l-1.21 17.48z"
              transform="translate(235.48 .47)"></path>
            <path
              stroke="#FFF"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M292.16 245.34l28.73-21.76a10.14 10.14 0 004.02-8.13l-.02-3.6c0-.97-1.06-1.58-1.9-1.09l-29.62 17.1-1.21 17.48zm-4.42 6.22l-48.54-28.02m48.34 31.77L239 227.3m48.34 31.76l-48.53-28.02"></path>
          </g>
        </g>
        <g id="four-trucks-light-truck" transform="translate(64.53 477.39)">
          <path
            fill="#001224"
            d="M53.15 48.59v21.86L27.04 83.57l-10.12 29.57C6.06 121.32.63 127.63.63 132.04c0 4.43 1.15 11.3 3.46 20.6L54.7 179l131.13-79.42v-70.5L134.7 0 53.15 48.59z"></path>
          <path fill="#FFF" d="M22.57 26L67.38.13" transform="translate(85.32 122.38)"></path>
          <path
            stroke="#001428"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M85.78 162.58l66.92-40.07"></path>
          <path fill="#FFF" d="M.32.52v4.96" transform="translate(82.54 150.78)"></path>
          <path
            stroke="#001428"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M82.86 151.3v4.96"></path>
          <path
            fill="#001428"
            d="M160.99 111.68c-5.3 3.09-9.63 10.55-9.63 16.7 0 6.15 4.33 8.63 9.63 5.55 5.36-3.05 9.66-10.54 9.66-16.7 0-6.14-4.3-8.64-9.66-5.55"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M160.99 111.68c-5.3 3.09-9.63 10.55-9.63 16.7 0 6.15 4.33 8.63 9.63 5.55 5.36-3.05 9.66-10.54 9.66-16.7 0-6.14-4.3-8.64-9.66-5.55z"></path>
          <path
            fill="#001428"
            d="M158.9 105.86c-1.72-.94-4.08-.76-6.69.72-5.33 3.08-9.65 10.58-9.65 16.72 0 2.95 1 5.06 2.63 6.12l8.77 5.07c-1.6-1.06-2.6-3.17-2.6-6.11 0-6.15 4.33-13.61 9.63-16.7 2.67-1.52 5.04-1.67 6.77-.72l-8.85-5.1h-.02z"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M158.9 105.86c-1.72-.94-4.08-.76-6.69.72-5.33 3.08-9.65 10.58-9.65 16.72 0 2.95 1 5.06 2.63 6.12l8.77 5.07c-1.6-1.06-2.6-3.17-2.6-6.11 0-6.15 4.33-13.61 9.63-16.7 2.67-1.52 5.04-1.67 6.77-.72l-8.85-5.1h-.02z"></path>
          <path
            fill="#001428"
            d="M160.99 114.9c-3.76 2.2-6.82 7.5-6.82 11.85 0 4.37 3.06 6.14 6.82 3.97 3.8-2.19 6.86-7.48 6.86-11.87 0-4.36-3.06-6.1-6.86-3.94"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M160.99 114.9c-3.76 2.2-6.82 7.5-6.82 11.85 0 4.37 3.06 6.14 6.82 3.97 3.8-2.19 6.86-7.48 6.86-11.87 0-4.36-3.06-6.1-6.86-3.94z"></path>
          <path d="M160.99 120.36a4.7 4.7 0 00-2.1 3.67c0 1.36.94 1.9 2.1 1.22a4.67 4.67 0 002.12-3.67c0-1.32-.95-1.88-2.12-1.22"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M160.99 120.36a4.7 4.7 0 00-2.1 3.67c0 1.36.94 1.9 2.1 1.22a4.67 4.67 0 002.12-3.67c0-1.32-.95-1.88-2.12-1.22z"></path>
          <path
            fill="#001428"
            d="M79.69 159.04c-5.3 3.09-9.64 10.54-9.64 16.7 0 6.15 4.34 8.63 9.64 5.55 5.36-3.05 9.66-10.54 9.66-16.68 0-6.14-4.3-8.66-9.66-5.57"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M79.69 159.04c-5.3 3.09-9.64 10.54-9.64 16.7 0 6.15 4.34 8.63 9.64 5.55 5.36-3.05 9.66-10.54 9.66-16.68 0-6.14-4.3-8.66-9.66-5.57z"></path>
          <path
            fill="#001428"
            d="M77.6 153.22c-1.73-.93-4.08-.77-6.69.72-5.34 3.09-9.65 10.57-9.65 16.73 0 2.94 1 5.04 2.63 6.12l8.77 5.06c-1.6-1.06-2.6-3.17-2.6-6.11 0-6.16 4.33-13.61 9.63-16.7 2.66-1.5 5.03-1.67 6.77-.71l-8.85-5.11h-.02z"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M77.6 153.22c-1.73-.93-4.08-.77-6.69.72-5.34 3.09-9.65 10.57-9.65 16.73 0 2.94 1 5.04 2.63 6.12l8.77 5.06c-1.6-1.06-2.6-3.17-2.6-6.11 0-6.16 4.33-13.61 9.63-16.7 2.66-1.5 5.03-1.67 6.77-.71l-8.85-5.11h-.02z"></path>
          <path
            fill="#001428"
            d="M79.69 162.27c-3.76 2.2-6.82 7.5-6.82 11.84 0 4.37 3.06 6.14 6.82 3.96 3.8-2.17 6.86-7.48 6.86-11.85 0-4.37-3.07-6.12-6.86-3.95"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M79.69 162.27c-3.76 2.2-6.82 7.5-6.82 11.84 0 4.37 3.06 6.14 6.82 3.96 3.8-2.17 6.86-7.48 6.86-11.85 0-4.37-3.07-6.12-6.86-3.95z"></path>
          <path
            fill="#001428"
            d="M79.69 167.72a4.73 4.73 0 00-2.1 3.67c0 1.36.94 1.9 2.1 1.22a4.66 4.66 0 002.13-3.66c0-1.34-.96-1.89-2.13-1.23"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M79.69 167.72a4.73 4.73 0 00-2.1 3.67c0 1.36.94 1.9 2.1 1.22a4.66 4.66 0 002.13-3.66c0-1.34-.96-1.89-2.13-1.23z"></path>
          <path
            fill="#001428"
            d="M53.15 171.08l15.04-8.31 5.67-9 87.35-49.81-8.5-.23-95.3 53.99z"></path>
          <path
            fill="#001224"
            d="M.59 2.37v8.16c0 3.55 1.89 6.83 4.96 8.6l43.1 24.88a9.93 9.93 0 009.2.37l8.33-3.95v-5.1c0-1.75 2.8-13.07 7.85-17.3l10.7-6.18c2.35-1.25 3.13.18 3.46 1.38l12.46-7.2V.14l-27.2 15.7-5.85 8.54-14.45 8.35L.6 2.37z"
            transform="translate(0 138.37)"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M.59 140.74v8.07c0 3.55 1.89 6.83 4.96 8.6l43.1 24.88a9.93 9.93 0 009.2.37l8.33-3.95v-5c0-1.77 2.8-13.08 7.85-17.32.57-.33 7.26-4.53 7.83-4.86 2.35-1.25 3.13.18 3.46 1.37l15.33-8.5v-5.91l-27.2 15.71-6.35 7.14-15.03 8.74L.59 140.74z"></path>
          <path
            fill="#001224"
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M145.13 128.31c0-1.76 0-11.33 7.54-14.86.57-.33 6.73-3.76 9.1-5.17 3.43-1.83 6.25 1.78 6.58 2.97l17.49-9.1v-11.8l-84.07 47.62-18.9 10.79 1.57 1.56 1.34 1.84 1.68 7.47 57.67-31.32z"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M19.17 148.64l15.76 9.1 1.27-6.96-18.32-10.7z"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M39.7 158.89l9.88 5.7v-4.21l-9.88-5.71z"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M4.09 138.32l9.88 5.7v-4.2l-9.88-5.7z"></path>
          <path
            fill="#FFF"
            d="M28.04 57.85l-11.5 26.13 48.84 26.36 11.83-27.88-49.17-24.6zm.26.7L76.55 82.7l-11.42 26.93L17.2 83.75l11.1-25.2z"
            transform="translate(.38 29.05)"></path>
          <g transform="translate(.38 29.08)">
            <path
              fill="#001224"
              d="M.16 36.32c.54-.18 23.16-12.8 23.16-14.08 0-1.27.55-20.26 0-21.35-.54-1.09-2.18 0-2.18 0L7.88 8.34S-.02 28.05.07 28.6"
              transform="translate(73.57 77.66)"></path>
            <path
              stroke="#FFF"
              strokeWidth="0.5"
              d="M73.73 110.92c.54-.19 23.17-12.81 23.17-14.08 0-1.28.54-17.2 0-18.29-.55-1.09-2.19 0-2.19 0L81.45 86s-7.9 19.71-7.81 20.26"></path>
            <path stroke="#288CFF" strokeWidth="0.5" d="M101.4 124.33V47.87L185.46 0"></path>
            <path fill="#FFF" d="M1.1.28v50.58" transform="translate(130.8 52.11)"></path>
            <path
              stroke="#FFF"
              strokeWidth="0.5"
              d="M.26 112.27s.14-5.66.76-11.11c.61-5.46 14-17.05 14-17.05l12.42-28.78 25.13-13.96 48.83 25.76-21.28 13.1-13.66 32.61s-13.39 11.6-14 17.05a147 147 0 00-.77 11.12m21.95-34.75v4.66"></path>
            <path
              stroke="#FFF"
              strokeWidth="0.5"
              d="M19.63 90.59l34.71 18.58-3.2.72-31.51-16.4z"></path>
            <path
              stroke="#FFF"
              strokeWidth="0.5"
              d="M32.83 61.86l-5.02-2.68.39-.97 48.68 24.41-.52.87-8.54-4.1-1.71.73L33.8 64z"></path>
          </g>
          <path
            stroke="#288CFF"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M52.72 48.59L135.18 0l50.66 29.05"></path>
          <path stroke="#288CFF" strokeWidth="0.5" d="M185.84 29.08v73.07"></path>
          <path fill="#D8D8D8" stroke="#288CFF" strokeWidth="0.5" d="M53.15 48.59v21.86"></path>
          <path
            fill="#D8D8D8"
            stroke="#288CFF"
            strokeWidth="0.5"
            d="M101.77 76.94L53.15 48.59"></path>
          <path stroke="#288CFF" strokeWidth="0.5" d="M101.77 137.97l84.07-47.63"></path>
        </g>
        <g id="four-trucks-package-bubble" strokeWidth="1.43">
          <path
            fill="#008DFF"
            stroke="#008DFF"
            d="M108.03 300.55a22.82 22.82 0 100-45.63 22.82 22.82 0 000 45.63z"></path>
          <path
            stroke="#FFF"
            d="M103.26 274.46l9.45-5.04m-13.96 2.72l9.28 4.64 9.28-4.64m-9.28 15.92v-11.28"></path>
          <path
            stroke="#FFF"
            d="M108.88 267.62l7.67 3.84 1.07.55v11.46l-1.07.56-7.67 3.83-.85.43-.86-.43-7.68-3.83-1.05-.56V272l1.06-.55 7.67-3.84.86-.43z"></path>
          <path stroke="#008DFF" strokeLinecap="round" d="M108.03 300.56v51.84"></path>
        </g>
        <g id="four-trucks-zap-bubble">
          <g transform="translate(332.64 213.92)">
            <mask id="four-trucks-mask-2" fill="#fff">
              <path d="M0 23.35a22.82 22.82 0 0045.64 0 22.82 22.82 0 10-45.64 0z"></path>
            </mask>
            <path
              fill="#008DFF"
              d="M-3.57 49.74H49.2V-3.03H-3.57z"
              mask="url(#four-trucks-mask-2)"></path>
          </g>
          <path
            stroke="#008DFF"
            strokeWidth="1.43"
            d="M355.46 260.09a22.82 22.82 0 100-45.64 22.82 22.82 0 000 45.64z"></path>
          <path
            stroke="#008DFF"
            strokeLinecap="round"
            strokeWidth="1.43"
            d="M355.46 260.09v51.85"></path>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth="1.07"
            d="M348.35 248.5l13.52-12.7h-5.02l5.02-9.04-12.38 10.87h4.87z"></path>
        </g>
        <g id="four-trucks-gas-bubble">
          <g transform="translate(315.17)">
            <mask id="four-trucks-mask-4" fill="#fff">
              <path d="M0 23.28a22.82 22.82 0 0045.64 0 22.82 22.82 0 00-45.64 0z"></path>
            </mask>
            <path
              fill="#008DFF"
              d="M-3.57 49.66H49.2V-3.11H-3.57z"
              mask="url(#four-trucks-mask-4)"></path>
          </g>
          <path
            stroke="#008DFF"
            strokeLinecap="round"
            strokeWidth="1.43"
            d="M337.99 46.09v51.85"></path>
          <g stroke="#FFF" strokeWidth="1.43">
            <path d="M333.04 12.52h6.81a2.85 2.85 0 012.85 2.85v17.46h-12.5V15.37a2.85 2.85 0 012.85-2.85z"></path>
            <path d="M333.58 15.79h5.73c.4 0 .71.32.71.71v1.84c0 .4-.32.71-.71.71h-5.73a.71.71 0 01-.71-.71V16.5c0-.4.31-.71.7-.71zm9.12 4.84c2 1.24 3.01 2 3.01 2.27v5.45c.13 1.03.72 1.55 1.76 1.55 1.05 0 1.57-.52 1.56-1.55V14.83c-.02-.44-.2-.78-.53-1.01l-2.79-1.95"></path>
            <path strokeLinecap="round" d="M344.46 32.83h-15.74"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
